import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterSection,TopFooterWrapper,MiddleFooterWrapper,FooterBtn,FooterBtnRound,FooterCol,
    FooterSocial,BottomFooterPara,BottomFooterRight,FooterBtnWrapper,
    FooterAddWrapper,FooterAddLeft,FooterAddRight,BottomFooterCol,
    BottomFooterWrapper,BottomLink,FbIcon,TwitterIcon,LinkedinIcon,
    InstaIcon} from './footer.style';
import ButtonArrow from "../Common/buttonarrow"
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const Footer = (props) =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            headerFooterJson {
                Footer {
                    FooterText
                    FooterBtn
                    FooterLogo
                    Items
                    FooterCol1{
                        FooterAddWrapper{
                            FooterAddImg
                            FooterAddText
                        }
                    }
                    FooterCol2{
                        FooterUl{
                            FooterMenu
                            Href
                        }
                    }
                    FooterMap{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
    `);
    const FooterData = JSONData.headerFooterJson.Footer;
    
    return (
        <div>
        <FooterSection>
            <Container>
                <TopFooterWrapper>
                    <Row>
                        <Col md="8">
                            <h3>{FooterData.FooterText}</h3>
                        </Col>
                        <Col md="4">
                            <FooterBtnWrapper>
                                {
                                    props.templateType !== "type2" &&
                                    <FooterBtn href="/">{FooterData.FooterBtn}</FooterBtn>
                                }
                                {
                                    props.templateType === "type2" &&
                                    <FooterBtnRound href="/"><span>{FooterData.FooterBtn}</span><ButtonArrow/></FooterBtnRound>
                                }
                            </FooterBtnWrapper>
                        </Col>
                    </Row>
                </TopFooterWrapper>
                <MiddleFooterWrapper>
                    <Row>
                        <Col lg="3" md="6">
                            <FooterCol>
                                {
                                    FooterData.FooterCol1.FooterAddWrapper.map((item) => {
                                    return <FooterAddWrapper>
                                            <FooterAddLeft>
                                                <img src={item.FooterAddImg} alt=""/>
                                            </FooterAddLeft>
                                            <FooterAddRight>
                                                <p>
                                                {item.FooterAddText}
                                                </p>
                                            </FooterAddRight>
                                        </FooterAddWrapper>
                                    })
                                }
                                <BottomFooterRight>
                                    <FooterSocial href="/" aria-label="Facebook Link">
                                        <FbIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="/" aria-label="Twitter Link">
                                        <TwitterIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="/" aria-label="Linkedin Link">
                                        <LinkedinIcon/>
                                    </FooterSocial>
                                    <FooterSocial href="/" aria-label="Instagram Link">
                                        <InstaIcon/>
                                    </FooterSocial>
                                </BottomFooterRight>
                            </FooterCol>
                        </Col>
                        <Col lg="3" md="6">
                            <FooterCol>
                                <ul>
                                    {
                                        FooterData.FooterCol2.FooterUl.map((item) => {
                                        return <li>
                                            <a href={item.Href}>
                                                {item.FooterMenu}
                                            </a>
                                        </li>
                                        })
                                    }
                                </ul>
                            </FooterCol>
                        </Col>
                        <Col lg="6" md="12">
                            <FooterCol>
                                <GatsImg
                                    fluid={FooterData.FooterMap.childImageSharp.fluid}
                                    alt=""
                                />
                            </FooterCol>
                        </Col>
                    </Row>
                </MiddleFooterWrapper>
            </Container>
        </FooterSection>
        <BottomFooterWrapper>
            <Container>
                <Row>
                    <Col md="6" sm="6">
                        <BottomFooterCol>
                            <img src={FooterData.FooterLogo} alt=""/>
                        </BottomFooterCol>
                    </Col>
                    <Col md="6" sm="6">
                        <BottomFooterPara>
                            {FooterData.BottomFooterPara}
                            <BottomLink rel="noreferrer" href={FooterData.BottomFooterLink} target="_blank">
                            {FooterData.BottomFooterName}
                            </BottomLink>
                        </BottomFooterPara>
                    </Col>
                </Row>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default Footer;
