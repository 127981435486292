import styled from 'styled-components';
import {Commonbtn,CommonbtnRound} from '../Common/common.style';
import {device} from '../Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'
import SectionCircleImg from '../../assets/DesignIcons/circle-pattern-3.png';
import SectionRoundedImg from '../../assets/DesignIcons/circle-pattern-1.png';
import BackgroundImg from '../../assets/DesignIcons/circle-pattern-4.png';


export const FooterSection = styled.footer`
    width:100%;
    background:#f9fafc;
    padding:70px 0px 70px;
    position:relative;
    h3{
        margin-bottom:0px;
    }
    :before{
        content: "";
        display: block;
        position: absolute;
        top: -120px;
        background-size: 100%;
        width: 250px;
        height: 250px;
        right: 0px;
        background-position-x: 120px;
        z-index: -1;
        background-repeat: no-repeat;
        background-image: url(${SectionCircleImg});
    }


    }

    @media ${device.laptop} {
        h3{
            font-size:30px;
        }
    }
    @media ${device.tablet} {
        padding:80px 5px 50px;
        h3{
            margin-bottom:30px;
        }
    }
`;

export const FooterBtn = styled(Commonbtn)`

`;

export const FooterBtnRound = styled(CommonbtnRound)`

`;

export const FooterBtnWrapper = styled.div`
    text-align:right;
    @media ${device.tablet} {
        text-align:center;
    }
`;


export const TopFooterWrapper = styled.div`
    padding-bottom: 60px;
    border-bottom:1px solid #ddd;
    :before{
        content: "";
        display: block;
        position: absolute;
        top: 110px;
        background-size: 100%;
        width: 150px;
        height: 150px;
        left: 0px;
        background-repeat: no-repeat;
        background-position-x: -100px;
        background-image: url(${SectionRoundedImg});
    }
    .row{
        align-items:center;
    }
    @media ${device.tablet}{
        flex-direction:column;
        text-align:center;
    }
;`

export const MiddleFooterWrapper = styled.div`
    margin-top:30px;
;`
export const FooterCol = styled.div`
    padding:30px 0px;

    ul{
        margin-left:0px;
        column-count: 2;
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
    }
    li{
        list-style:none;
        margin-bottom:6px;
    }
    a{
        text-decoration:none;
        color:#424242;

        :hover{
            text-decoration:underline;
        }
    }
`;

export const FooterSocial = styled.a`
    margin-right:5px;
`;

export const FooterAddWrapper = styled.div`
    display:flex;
    align-items:top;
    margin-bottom: 30px;
`;

export const FooterAddLeft = styled.div`
    width:30px;
    flex-shrink:0;
    img{
        margin-bottom: 0px;
        width:30px;
    }
`;


export const FooterAddRight = styled.div`
    p{
        font-size:16px;
        margin-left:10px;
        margin-bottom: 0px;
    }
`;


export const BottomFooterWrapper = styled.section`
    width:100%;
    padding:20px 0px;
    background:#fdfdfe;
    position:relative;
    overflow:hidden;
    :after {
        content: "";
        display: block;
        position: absolute;
        background-size: 100%;
        width: 250px;
        height: 250px;
        right: 0;
        background-position: center;
        text-align: center;
        left: 0;
        margin: auto;
        bottom: 0;
        background-repeat: no-repeat;
        background-image: url(${BackgroundImg});
        background-position-y: 180px;
    }
    .row{
        align-items: center;
        justify-content:space-between;
    }

    @media ${device.tablet} {
        padding-left:10px;
        padding-right:10px;
    }
`;
export const BottomFooterPara = styled.p`
    margin-bottom:0px;
    font-size:15px;
    text-align:right;
    color:#7a7f83;
    @media ${device.tablet} {
        margin-bottom:0px;
    }
    @media ${device.mobileXL} {
        text-align:center;
    }
`;

export const BottomLink = styled.a`
    font-size:15px;
    margin-bottom:0px;
    padding:10px 0px;
    color:#7a7f83;
    text-decoration:none;
    :hover{
        text-decoration:underline;
    }
`;

export const BottomFooterRight = styled.div`
    text-align:left;
    a{
        display:inline-block;
        margin-bottom:10px;
    }
    @media ${device.tablet} {
        text-align:left;
    }
`;


export const InstaIcon = styled(SocialInstagram)`
    width: 45px;
    color: #333;
    padding: 10px;
    border-radius:100%;
    margin: 0px 5px 0px 0px;
    border: 1.5px solid #333;
    transition:all .5s;
    :hover{
        color: #fff;
        background: #333;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 45px;
    color: #333;
    padding: 10px;
    border-radius:100%;
    margin: 0px 5px 0px 0px;
    border: 1.5px solid #333;
    transition:all .5s;
    :hover{
        color: #fff;
        background: #333;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 45px;
    color: #333;
    padding: 10px;
    border-radius:100%;
    margin: 0px 5px 0px 0px;
    border: 1.5px solid #333;
    transition:all .5s;
    :hover{
        color: #fff;
        background: #333;
    }
`;

export const FbIcon = styled(SocialFacebook)`
    width: 45px;
    color: #333;
    padding: 10px;
    border-radius:100%;
    margin: 0px 5px 0px 0px;
    border: 1.5px solid #333;
    transition:all .5s;
    :hover{
        color: #fff;
        background: #333;
    }
`;
export const BottomFooterCol = styled.div`
    img{
        max-width: 170px;

        @media ${device.laptop} {
            max-width: 140px;
        }

        @media ${device.tablet} {
            max-width: 170px;
        }
    }
    @media ${device.mobileXL} {
        text-align:center;
        margin:auto;
        margin-bottom:10px;
    }
`;
