import React from "react";

const ButtonArrow = () => (
    <svg version="1.1"
    	 id="svg2" xmlnsDc="http://purl.org/dc/elements/1.1/" xmlnsCc="http://creativecommons.org/ns#"
    	 xmlnsRdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlnsSvg="http://www.w3.org/2000/svg"
    	 xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    	 width="25px" height="11.187px" viewBox="328.947 292.76 25 11.187" enableBackground="new 328.947 292.76 25 11.187" xmlSpace="preserve">
    <g id="g10" transform="matrix(1.3333333,0,0,-1.3333333,0,596.70667)">
    	<g id="g12" transform="scale(0.1)">
    		<path id="path14" fill="#FFFFFF" d="M2654.015,2239.125l-40.027,39.895c-0.571,0.568-1.421,0.732-2.175,0.432
    			c-0.754-0.311-1.238-1.04-1.238-1.849v-27.62h-141.467c-1.106,0-2.007-0.897-2.007-2v-20.75c0-1.106,0.901-2,2.007-2h141.467
    			v-27.539c0-0.806,0.491-1.538,1.238-1.846c0.249-0.103,0.505-0.154,0.762-0.154c0.52,0,1.033,0.205,1.421,0.586l40.027,40.012
    			c0.374,0.374,0.579,0.883,0.579,1.414S2654.396,2238.747,2654.015,2239.125"/>
    	</g>
    </g>
    </svg>

)
export default ButtonArrow;